import { css } from "@emotion/react";
import { IconButton, Stack } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  gridPageCountSelector,
  gridPaginationModelSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { customScrollbar, transition_all } from "../config/styles";
import {
  primaryColor,
  secondaryColor,
  tableHeaderBgColor,
} from "../config/theme";
import { useUserContext } from "../context/UserContext";

interface IAppTable extends DataGridProps {
  accentColor?: string;
  dividerColor?: string;
  hasNext?: boolean;
  disableRowClick?: boolean;
  pageSize?: number;
}

/**
 * The default Table component that implements the table design with default options
 */
export const AppTable = ({ ...props }: IAppTable) => {
  const { user } = useUserContext();
  return (
    <DataGrid
      getRowId={(row) => row.entry}
      {...props}
      css={appTableStyle(
        props.disableRowClick!,
        String(user?.teamId),
        Boolean(props.getRowHeight),
        props.dividerColor
      )}
      onRowClick={!props.disableRowClick ? props.onRowClick : undefined}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: props.pageSize || 50,
          },
        },
      }}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnResize
      disableColumnSelector
      disableMultipleRowSelection
      disableColumnSorting
      slots={{
        noRowsOverlay: () => (
          <Stack
            css={css`
              justify-content: center;
              align-items: center;
              height: 100%;
            `}
          >
            No Data to Display
          </Stack>
        ),
        pagination: () => (
          <CustomPagination
            accentColor={props.accentColor}
            hasNext={props.hasNext}
          />
        ),
      }}
    />
  );
};

/** custom App table pagination that implements the pagination buttons design */
const CustomPagination = (props: {
  accentColor?: string;
  hasNext?: boolean;
}) => {
  const apiRef = useGridApiContext();
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const nextPage = () => {
    apiRef.current?.setPage(paginationModel.page + 1);
  };

  const previousPage = () => {
    apiRef.current?.setPage(paginationModel.page - 1);
  };

  return (
    <Stack css={paginationStyle(props.accentColor)}>
      <IconButton onClick={previousPage} disabled={paginationModel.page === 0}>
        <ArrowForwardRoundedIcon sx={{ transform: "rotate(180deg)" }} />
      </IconButton>

      {/* Page Number */}
      <>
        Page {paginationModel.page + 1} {pageCount ? `of ${pageCount}` : null}
      </>

      <IconButton
        onClick={nextPage}
        disabled={
          props.hasNext !== undefined
            ? !props.hasNext
            : paginationModel.page === pageCount - 1
        }
      >
        <ArrowForwardRoundedIcon />
      </IconButton>
    </Stack>
  );
};

const paginationStyle = (color: string | undefined) => css`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  font-size: 0.875rem;
  background-color: transparent;

  button {
    ${transition_all}
    color: white;
    border: 2px solid white;
    color: ${color || secondaryColor};
    border: 2px solid ${color || secondaryColor};
    padding: 0.25rem;
    border-radius: 0.5rem;
  }

  button:disabled {
    color: #535360;
    border: 2px solid #535360;
  }
`;

const appTableStyle = (
  disableRowClick: boolean,
  teamId: string,
  isRowHeightAuto: boolean,
  dividerColor?: string
) => css`
  /**.MuiDataGrid-root */
  color: white;
  border: none;
  font-size: clamp(0.75rem, 1.5vw, 1rem);

  .MuiDataGrid-virtualScroller,
  .MuiDataGrid-main,
  .MuiDataGrid-virtualScrollerContent {
    border: none;
  }

  .MuiDataGrid-sortIcon {
    color: white;
  }

  .MuiDataGrid-footerContainer {
    border: none;
  }

  .MuiDataGrid-main {
    background-color: ${tableHeaderBgColor};
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;

    div[data-id="${teamId}"] {
      background: ${primaryColor}55;

      :hover {
        background: ${primaryColor}99;
      }
    }
  }

  /** Table Header */
  .MuiDataGrid-columnHeaders {
    div {
      background-color: ${tableHeaderBgColor} !important;
      border: none;
    }
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-filler {
    background-color: ${tableHeaderBgColor};
    height: auto !important; /* Automatically adjusts the height of the table body to fit the content, ensuring there are no empty rows on any page, including when the last page contains fewer entries. */
    border: none;
    text-align: center;
  }

  .MuiDataGrid-columnHeaderTitle {
    white-space: pre-wrap;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-topContainer::after {
    background-color: ${dividerColor || "#181829"};
    height: 2px;
  }

  .MuiDataGrid-filler {
    display: none;
  }

  // Hight Auto

  ${isRowHeightAuto &&
  css`
    &.MuiDataGrid-root--densityCompact .MuiDataGrid-cell {
      padding-block: 8px;
    }

    &.MuiDataGrid-root--densityStandard .MuiDataGrid-cell {
      padding-block: 15px;
    }

    &.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell {
      padding-block: 22px;
    }
  `}

  .MuiDataGrid-cell {
    border: none;
  }

  .MuiDataGrid-row {
    cursor: pointer;
    border-bottom: 1px solid ${dividerColor || "#181829"};
  }

  ${!disableRowClick &&
  css`
    .MuiDataGrid-row:hover {
      background-color: #1a1a24;
    }
  `}

  .MuiDataGrid-scrollbar--vertical {
    ${customScrollbar}
  }

  .MuiDataGrid-scrollbar {
    z-index: 0; // fix scrollbar in mini league tables specifically
  }

  .MuiDataGrid-scrollbar--horizontal {
    margin-block: 0.5rem;
    /* top: 0rem; */
    ${customScrollbar}
  }

  .MuiDataGrid-main:has(.MuiDataGrid-scrollbar--horizontal)
    > .MuiDataGrid-virtualScroller {
    margin-block: 0.5rem 1rem;
  }

  // Remove border of scrollbar container inside cell
  .MuiDataGrid-scrollbarFiller {
    border: none !important;
  }

  // overlay container
  .MuiDataGrid-overlayWrapper {
    height: 20rem;
  }
`;
